<template>
  <div class="finish-container">
    <div style="text-align: left">
      <img class="logostyle" src="@/assets/img/eSigns Logo Black.svg" />
    </div>

    <div class="pop">
      <img
        class="img_sty"
        alt="Document Sent"
        src="@/assets/img/Finish Later.svg"
      />
      <p class="f_text">Got busy with something? No problem!</p>

      <p class="f_text1">
        You can resume and complete the document by using the original link
        received through email or by signing into eSigns
      </p>
      <p class="booo">----------------------------------------------</p>
      <p class="f_text2">Get started with eSigns, it's easy!</p>
      <p class="f_text3">
        Leverage the automation technology in eSigns to send your documents and
        get them signed in minutes,rather than days.
      </p>
      <a style="color: #ffffff; text-decoration: none"
        ><el-button
         @click="goToDashboard"
          style="
            margin-bottom: 15px;
            background-color: #f562b3;
            color: #ffffff;
            text-align: center;
            letter-spacing: 0.1em;
          "
        >
          TRY IT</el-button
        >
        </a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Global-finish-later",
  methods:{
    goToDashboard(){
      this.$router.push({
        name: "Dashboard",
      });
    }
  }
};
</script>
<style scoped>
.finish-container {
  text-align: center;
}
.img_sty {
  width: 120px;
  height: 100px;
  margin-top: 90px;
  align-items: center;
}
.f_text {
  font-size: 32.5px;
  line-height: 35.73px;
  align-content: center;
  color: #212529;
  margin-top: 20px;
  font-weight: 600;
}
.f_text1 {
  line-height: 30.2px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  font-size: 22px;
  color: #01040a;
  font-weight: 300;
}
.booo {
  color: #dcdfe6;
  line-height: 30px;
}
.f_text2 {
  /* height: 29px; */
  font-weight: 600;
  font-size: 27px;
  line-height: 35.5px;
  color: #212529;
}
.f_text3 {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;
  /* display: flex;
  align-items: center; */
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  color: #01040a;
}

.logostyle {
  width: 153.67px;
  height: 65.01px;
  margin-left: 40px;
  margin-top: 40px;
}
.el-button {
  position: static;
  width: 100px;
  height: 44px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
</style>